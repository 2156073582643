import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: true,
    title: "Наименование фонда",
    tooltip: "Наименование фонда",
    className: "info-modal__element_full-size",
    value: fields.FUND_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Краткое наименование фонда",
    tooltip: "Краткое наименование фонда",
    className: "info-modal__element_title",
    value: fields.FUND_SHORT_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "50",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Код фонда",
    tooltip: "Код фонда (Идентификатор фонда при взаимодействии с ОИК)",
    className: "info-modal__element_title",
    value: fields.FUND_CODE,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    title: "ОИК",
    tooltip: "ОИК",
    className: "info-modal__element_title",
    value: fields.FUND_OIK,
    slotName: "selectOik",
  },
  {
    id: uuid(),
    title: "Архив",
    tooltip: "Архив",
    className: "info-modal__element_title",
    value: fields.FUND_ARCHIVE,
    slotName: "selectArchive",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "№ фонда",
    tooltip: "№ фонда",
    className: "info-modal__element_title",
    value: fields.FUND_NUMBER,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Аннотация",
    tooltip: "Аннотация",
    className: "info-modal__element_full-size info-modal__element_textarea info-modal__annotation",
    value: fields.FUND_DESCRIPTION,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "20000",
    fieldType: "textarea",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Историческая справка",
    tooltip: "Историческая справка",
    className: "info-modal__element_full-size info-modal__element_textarea info-modal__about",
    value: fields.FUND_HISTORICAL_REFERENCE,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "20000",
    fieldType: "textarea",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Начальная дата фонда",
    tooltip: "Начальная дата фонда",
    className: "info-modal__element_title",
    value: fields.FUND_DATE_FIRST_ARRIVAL,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Конечная дата фонда",
    tooltip: "Конечная дата фонда",
    className: "info-modal__element_title",
    value: fields.FUND_DATE_LAST_ARRIVAL,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество дел в фонде",
    tooltip: "Количество дел в фонде",
    className: "info-modal__element_title",
    value: fields.FUND_NUMBER_OF_CASES,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Количество ЭАД в фонде",
    tooltip: "Количество ЭАД в фонде",
    className: "info-modal__element_title",
    value: fields.FUND_NUMBER_OF_EADS,
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор фонда",
    tooltip: "Идентификатор фонда",
    className: "info-modal__element_title",
    value: fields.FUND_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Состояние",
    tooltip: "Состояние может принимать значения: Активный, Удаленный",
    className: "info-modal__element_title",
    value: fields.FUND_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, сохранивший запись",
    tooltip: "Пользователь, сохранивший запись",
    className: "info-modal__element_title",
    value: fields.FUND_CREATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата сохранения",
    tooltip: "Дата сохранения записи",
    className: "info-modal__element_title",
    value: fields.FUND_CREATION_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    tooltip: "Пользователь, изменивший запись",
    className: "info-modal__element_title",
    value: fields.FUND_UPDATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последнего обновления",
    tooltip: "Дата последнего обновления записи",
    className: "info-modal__element_title",
    value: fields.FUND_UPDATE_DATE,
  },
];

export const fullModalElements = [
  ...baseModalElements,
  ...techElements,
  {
    id: uuid(),
    title: "Редактирование / создание архива",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление архива",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];
