import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор фонда",
    tooltip: "Идентификатор фонда в ЦХЭД",
    defaultWidth: 200,
    value: fields.FUND_ID,
    isHiddenByDefault: true,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код фонда",
    tooltip: "Код фонда (Идентификатор фонда при взаимодействии с ОИК)",
    defaultWidth: 120,
    value: fields.FUND_CODE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование фонда",
    tooltip: "Наименование фонда",
    defaultWidth: 200,
    value: fields.FUND_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Краткое наименование фонда",
    tooltip: "Краткое наименование фонда",
    isLink: false,
    defaultWidth: 170,
    value: fields.FUND_SHORT_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Архив",
    tooltip: "Краткое наименование архива",
    defaultWidth: 170,
    value: fields.FUND_PARENT_ID,
    linkRights: [authorities.ARCHIVE_LIST],
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "№ фонда",
    tooltip: "№ фонда",
    isLink: false,
    defaultWidth: 170,
    value: fields.FUND_NUMBER,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Начальная дата фонда",
    tooltip: "Начальная дата фонда",
    isLink: false,
    defaultWidth: 140,
    value: fields.FUND_DATE_FIRST_ARRIVAL,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Конечная дата фонда",
    tooltip: "Конечная дата фонда",
    isLink: false,
    defaultWidth: 140,
    value: fields.FUND_DATE_LAST_ARRIVAL,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество дел в фонде",
    tooltip: "Количество дел в фонде",
    isLink: false,
    defaultWidth: 140,
    value: fields.FUND_NUMBER_OF_CASES,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Количество ЭАД в фонде",
    tooltip: "Количество ЭАД в фонде",
    isLink: false,
    defaultWidth: 140,
    value: fields.FUND_NUMBER_OF_EADS,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Состояние",
    tooltip: "Состояние может принимать значения: Активный, Удалённый",
    isLink: false,
    defaultWidth: 150,
    value: fields.FUND_STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
