import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FUND_CODE]: {},
  [fields.FUND_SHORT_NAME]: {},
  [fields.FUND_NAME]: {},
  [fields.FUND_NUMBER]: {},
  [fields.FUND_DATE_FIRST_ARRIVAL_FROM]: "",
  [fields.FUND_DATE_FIRST_ARRIVAL_TO]: "",
  [fields.FUND_DATE_LAST_ARRIVAL_FROM]: "",
  [fields.FUND_DATE_LAST_ARRIVAL_TO]: "",
  [fields.FUND_TYPE]: [],
  [fields.FUND_STATUS]: [],
  [fields.FUND_ID]: {},
});
