import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IFundFields {
  FUND_ID: string;
  FUND_ARCHIVE: string;
  FUND_OIK: string;
  FUND_CODE: string;
  FUND_DATE_FIRST_ARRIVAL: string;
  FUND_DATE_LAST_ARRIVAL: string;
  FUND_DESCRIPTION: string;
  FUND_IS_DELETED: string;
  FUND_NAME: string;
  FUND_NUMBER: string;
  FUND_SHORT_NAME: string;
  FUND_NUMBER_OF_EADS: string;
  FUND_TYPE: string;
  FUND_STATUS: string;
  FUND_PARENT_ID: string;
  FUND_HISTORICAL_REFERENCE: string;
  FUND_NUMBER_OF_CASES: string;
  FUND_MARK: string;
  FUND_CREATE_USER: string;
  FUND_CREATION_DATE: string;
  FUND_UPDATE_USER: string;
  FUND_UPDATE_DATE: string;
  FUND_IS_DROPPED: string;
  FUND_DATE_FIRST_ARRIVAL_FROM: string;
  FUND_DATE_LAST_ARRIVAL_FROM: string;
  FUND_DATE_FIRST_ARRIVAL_TO: string;
  FUND_DATE_LAST_ARRIVAL_TO: string;
  FUND_CHECKBOX: string;
}

export const fields: IFundFields = {
  FUND_ID: "id",
  FUND_ARCHIVE: "archive",
  FUND_CODE: "code",
  FUND_DATE_FIRST_ARRIVAL: "dateFirstArrival",
  FUND_DATE_LAST_ARRIVAL: "dateLastArrival",
  FUND_DESCRIPTION: "description",
  FUND_OIK: "oik",
  FUND_IS_DELETED: "isDeleted",
  FUND_NAME: "name",
  FUND_NUMBER: "number",
  FUND_SHORT_NAME: "shortName",
  FUND_NUMBER_OF_EADS: "numberOfEads",
  FUND_TYPE: "fundType",
  FUND_STATUS: "status",
  FUND_PARENT_ID: "fundParentId",
  FUND_HISTORICAL_REFERENCE: "historicalReference",
  FUND_NUMBER_OF_CASES: "numberOfCases",
  FUND_MARK: "fundMark",
  FUND_CREATE_USER: "createUser",
  FUND_CREATION_DATE: "createDate",
  FUND_UPDATE_USER: "updateUser",
  FUND_UPDATE_DATE: "updateDate",
  FUND_IS_DROPPED: "fundIsDropped",
  FUND_DATE_FIRST_ARRIVAL_FROM: "dateFirstArrivalFrom",
  FUND_DATE_LAST_ARRIVAL_FROM: "dateLastArrivalFrom",
  FUND_DATE_FIRST_ARRIVAL_TO: "dateFirstArrivalTo",
  FUND_DATE_LAST_ARRIVAL_TO: "dateLastArrivalTo",
  FUND_CHECKBOX: headerCheckboxValue,
};
