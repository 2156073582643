import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { convertStatus } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import moment from "moment";

export const convertApiItemToUi = (item: IFundElement): IFundElement & { status: string } => ({
  ...item,
  status: convertStatus(item.isDeleted),
  [fields.FUND_PARENT_ID]: item.archive?.shortName ?? "",
  [fields.FUND_DATE_FIRST_ARRIVAL]: item.dateFirstArrival ? moment(item.dateFirstArrival).format("YYYY-MM-DD") : "",
  [fields.FUND_DATE_LAST_ARRIVAL]: item.dateLastArrival ? moment(item.dateLastArrival).format("YYYY-MM-DD") : "",
});
