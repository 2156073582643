import { IFundCardElement } from "@monorepo/catalog/src/views/FundView/types/fundCardElement";

export const convertSaveCardObject = (resultData: Partial<IFundCardElement>): Partial<IFundCardElement> => {
  return {
    archive:
      resultData.archive && Object.keys(resultData.archive).length
        ? {
            id: resultData.archive.id,
          }
        : {},
    oik:
      resultData.oik && Object.keys(resultData.oik).length
        ? {
            id: resultData.oik.id,
          }
        : {},
    id: resultData.id || "",
    code: resultData.code || "",
    description: resultData.description || "",
    historicalReference: resultData.historicalReference || "",
    name: resultData.name || "",
    number: resultData.number || "",
    shortName: resultData.shortName || "",
    isDeleted: !!resultData.isDeleted,
  };
};
