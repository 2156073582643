export const elementStatuses = [
  {
    title: "Действующие",
    value: "Действующие",
    bool: false,
  },
  {
    title: "Удаленные",
    value: "Удаленные",
    bool: true,
  },
];
