import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/archiveView/fields";
import { DictionaryStatuses } from "@monorepo/utils/src/variables/dictionaryStatuses";

export const convertStatus = (archiveIsDeleted: boolean): string => (!archiveIsDeleted ? DictionaryStatuses.ACTIVE : DictionaryStatuses.DELETED);

export const convertApiItemToUi = (item: IArchiveElement): IArchiveElement & { status: string; fundsList: string[] } => ({
  ...item,
  status: convertStatus(!!item[fields.ARCHIVE_IS_DELETED as keyof IArchiveElement]), // DictionaryStatuses.CONCATED
  fundsList: Array.isArray(item.funds)
    ? item.funds.map((item) => {
        return item.name;
      })
    : [],
});
