import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;
  const isOpenModalOnStart = !!root.$route.query.isOpenModal;

  onBeforeMount(() => {
    store.dispatch("fundCatalogView/getArchives");
    store.dispatch("fundCatalogView/getOiks");
    store.dispatch("fundCatalogView/getViewMode", isOpenModalOnStart);
  });
};

export default useGetTableLibs;
