import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IArchiveFields {
  ARCHIVE_ID: string;
  CONCATED: string;
  ARCHIVE_CODE: string;
  ARCHIVE_IS_DELETED: string;
  ARCHIVE_NAME: string;
  ARCHIVE_SHORT_NAME: string;
  ARCHIVE_TYPE: string;
  ARCHIVE_STATUS: string;
  ARCHIVE_DESCRIPTION: string;
  ARCHIVE_ADDRESS: string;
  ARCHIVE_CONTACT_EMAIL: string;
  ARCHIVE_CREATE_USER: string;
  ARCHIVE_CREATION_DATE: string;
  ARCHIVE_UPDATE_USER: string;
  ARCHIVE_UPDATE_DATE: string;
  ARCHIVE_CHECKBOX: string;
  ARCHIVE_OIK: string;
  ARCHIVE_FUND: string;
}

export const fields: IArchiveFields = {
  ARCHIVE_ID: "id",
  ARCHIVE_CODE: "code",
  CONCATED: "concated",
  ARCHIVE_IS_DELETED: "isDeleted",
  ARCHIVE_NAME: "name",
  ARCHIVE_SHORT_NAME: "shortName",
  ARCHIVE_TYPE: "archiveType",
  ARCHIVE_STATUS: "status",
  ARCHIVE_FUND: "fundsList",
  ARCHIVE_DESCRIPTION: "description",
  ARCHIVE_ADDRESS: "address",
  ARCHIVE_CONTACT_EMAIL: "archiveContactEmail",
  ARCHIVE_CREATE_USER: "createUser",
  ARCHIVE_CREATION_DATE: "createDate",
  ARCHIVE_UPDATE_USER: "updateUser",
  ARCHIVE_UPDATE_DATE: "updateDate",
  ARCHIVE_CHECKBOX: headerCheckboxValue,
  ARCHIVE_OIK: "oiks",
};
