import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";

export const filtersElements = [
  {
    value: fields.FUND_CODE,
    title: "Код фонда",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getAutocompleteByCode",
    width: 268,
  },
  {
    value: fields.FUND_SHORT_NAME,
    title: "Краткое наименование фонда",
    type: "autocomplete",
    showSelectedKey: "shortName",
    autocompleteActionName: "getAutocompleteByShortName",
    width: 268,
  },
  {
    value: fields.FUND_NAME,
    title: "Наименование фонда",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getAutocompleteByName",
    width: 268,
  },
  {
    value: fields.FUND_NUMBER,
    title: "№ фонда",
    type: "autocomplete",
    showSelectedKey: "number",
    autocompleteActionName: "getAutocompleteByNumber",
    width: 268,
  },
  {
    value: fields.FUND_DATE_FIRST_ARRIVAL,
    dateValues: [fields.FUND_DATE_FIRST_ARRIVAL_FROM, fields.FUND_DATE_FIRST_ARRIVAL_TO],
    title: "Начальная дата фонда",
    type: "datePeriod",
    width: 265,
  },
  {
    value: fields.FUND_DATE_LAST_ARRIVAL,
    dateValues: [fields.FUND_DATE_LAST_ARRIVAL_FROM, fields.FUND_DATE_LAST_ARRIVAL_TO],
    title: "Конечная дата фонда",
    type: "datePeriod",
    width: 265,
  },
  {
    value: fields.FUND_STATUS,
    title: "Состояние",
    type: "selectStatus",
    selectItemsField: "elementStatuses",
    width: 340,
  },
  {
    value: fields.FUND_ID,
    title: "Идентификатор фонда",
    type: "autocomplete",
    showSelectedKey: "id",
    autocompleteActionName: "getFundIdAutocompleteItems",
    width: 268,
  },
];
