


































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import FundInfoModal from "@monorepo/catalog/src/views/FundView/components/FundInfoModal.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/fundView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as FundCatalogView } from "@monorepo/catalog/src/store/modules/fundCatalogView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module, mapMutations } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/FundView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundView/viewTitle";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useGetTableLibs from "@monorepo/catalog/src/views/FundView/composables/useGetTableLibs";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { Route } from "vue-router";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import ConcatFooterButton from "@monorepo/uikit/src/components/tableViews/ConcatFooterButton.vue";
import RestoreFooterButton from "@monorepo/uikit/src/components/tableViews/RestoreFooterButton.vue";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/FundView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/fundView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/catalog/src/views/FundView/utils/defaultCurrentFilters";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/fundView/elementStatuses";
import useFooterActions from "@monorepo/utils/src/composables/useFooterActions";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const SECTION_NAME = "fundCatalogView";

const pluralFormTitles = ["Фонд", "Фонда", "Фондов"];

export default defineComponent({
  name: "FundView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    AddElement,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    FundInfoModal,
    ToggleDocumentView,
    Footer,
    ExportFooterButton,
    ConcatFooterButton,
    RestoreFooterButton,
    DeleteFooterButton,
    CasesTree: () =>
      import(
        /* webpackChunkName: "casesTree" */
        "@monorepo/catalog/src/views/FundView/components/CasesTree.vue"
      ),
  },
  data() {
    return {
      breadcrumbs,
      ModalType,
      viewTitle,
      viewUniqKey,
      isOnceSelected: false,
      ViewMode,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FUND_CODE, fields.FUND_NAME, fields.FUND_SHORT_NAME, fields.FUND_NUMBER, fields.FUND_ID],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "isOpenFilters",
      "cells",
      "isSelectAll",
      "infiniteId",
      "data",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        elementStatuses,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_EXPORT);
    },
    isShowConcatBtn(this: { isSelectAll: boolean; selectedNotDeletedLength: number; user: { authorities: { authority: authorities }[] } }): boolean {
      const isAuthority = [authorities.FUND_CONCAT, authorities.FUND_MODIFY].every((authority) => isAuthorityExist(this.user, authority));
      return !this.isSelectAll && this.selectedNotDeletedLength > 1 && isAuthority;
    },
    isCanDelete(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_DELETE);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_MODIFY);
    },
    // selectedNotDeletedLength(): number {
    //   if (this.isSelectAll) {
    //     return this.totalLength;
    //   }
    //
    //   const deletedItemsObject = this.data.reduce((result: { [key: string]: boolean }, item: IArchiveElement) => {
    //     result[item.id] = item.isDeleted;
    //     return result;
    //   }, {});
    //   const selectedNotDeletedLength = Object.entries(this.selectedIds).filter(([key, value]) => {
    //     return value && !deletedItemsObject[key];
    //   }).length;
    //
    //   return selectedNotDeletedLength;
    // },
    // isShowDeleteBtn(): boolean {
    //   const isAuthority = isAuthorityExist(this.user, authorities.FUND_DELETE);
    //   if (!isAuthority) {
    //     return false;
    //   }
    //
    //   if (this.isSelectAll) {
    //     return true;
    //   }
    //
    //   return !!this.selectedNotDeletedLength;
    // },
    isShowFooter(this: {
      isSelectAllElement: boolean;
      selectedIds: Record<string, boolean>;
      isShowExport: boolean;
      isShowDeleteBtn: boolean;
      isShowRestoreBtn: boolean;
    }): boolean {
      return (
        (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) &&
        (this.isShowExport || this.isShowDeleteBtn || this.isShowRestoreBtn)
      );
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "addOpenedId",
      "getExportData",
      "setIsOpenFilters",
      "getTreeData",
      "refreshScroll",
      "deleteElement",
      "restoreEntity",
    ]),
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    toggleDocumentView(view: ViewMode) {
      if (view === ViewMode.TREE) {
        this.getTreeData();
      }
    },
    openConcatModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ConcatFundModal"));
    },
    openModalIfNeed(this: { isOnceSelected: boolean; $route: Route; data: IFundElement[]; addOpenedId: (id: string) => void; showModal: boolean }) {
      if (this.isOnceSelected) {
        return;
      }

      this.isOnceSelected = true;
      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    changeType(type: ModalType) {
      this.type = type;
      if (type === ModalType.NEW) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.openCardModal(event.isDeleted as boolean);
          }
          break;
        case fields.FUND_PARENT_ID:
          this.moveByAuthorities(header, "/dictionaries/archive", {
            shortName: (event as unknown as Record<string, unknown>)[header.value] as string,
            id: (event.archive as { id: string })?.id as string,
            isOpenModal: "1",
          });

          break;
        default:
          break;
      }
    },
    openCardModal(this: { toggleModal: (value: boolean, type?: ModalType) => void; isCanEdit: boolean }, isDeleted: boolean) {
      if (isDeleted) {
        return this.toggleModal(true, ModalType.READ);
      }
      this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ); // NOT SIMPLIFIED!
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);
    const moveByAuthorities = useMoveByAuthorities(root);

    const openModal = () => {
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
        const isCanEdit = !!root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.FUND_MODIFY;
        });
        type.value = isCanEdit ? ModalType.EDIT : ModalType.READ;
      }
    };
    const { isShowDeleteBtn, isShowRestoreBtn, openDeleteModal, openRestoreModal } = useFooterActions(
      root,
      SECTION_NAME,
      pluralFormTitles,
      authorities.FUND_DELETE
    );

    useInitTableStoreModule(root, SECTION_NAME, FundCatalogView as Module<unknown, unknown>);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useOpenHistoryMode(root, SECTION_NAME, openModal);
    useGetTableLibs(root);
    useSetStartFilters(root, SECTION_NAME, [], ["number", "name", "shortName", "code"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      openModal();
    });

    return {
      filtersLoaded,
      showModal,
      type,
      sectionName,
      moveByAuthorities,
      isShowDeleteBtn,
      isShowRestoreBtn,
      openDeleteModal,
      openRestoreModal,
    };
  },
});
