var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backdrop",on:{"click":_vm.closeModal}},[_c('FormulateForm',{attrs:{"name":"fund-info-modal"},on:{"submit":_vm.onSave},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('div',{staticClass:"info-modal d-flex align-center justify-end",on:{"click":function($event){$event.stopPropagation();}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading && _vm.isShowAnimation)?_c('TmpLogoOverlay',{staticClass:"absolute"}):_vm._e()],1),_c('div',{class:[
          'info-modal__content d-flex flex-column',
          {
            'info-modal__content_three-quarter': _vm.cardModeResult === _vm.cardMode.THREE_QUARTHER,
            'info-modal__content_full': _vm.cardModeResult === _vm.cardMode.FULL,
          } ]},[(_vm.formValues[_vm.fields.FUND_STATUS])?_c('CardModalStatusChip',{attrs:{"isLoading":_vm.isLoading,"background-color":_vm.statusColor,"status":_vm.formValues[_vm.fields.FUND_STATUS]}}):_vm._e(),_c('card-modal-toggle-view-size',{attrs:{"section":_vm.section}}),_c('div',{staticClass:"info-modal__close-wrap"},[_c('v-btn',{attrs:{"data-test-id":"closeBtn","icon":""},nativeOn:{"click":function($event){return _vm.closeModal($event)}}},[_c('v-icon',{attrs:{"color":"#21201F"}},[_vm._v("mdi-close")])],1)],1),(_vm.isLoading)?_c('TmpBlock',{staticClass:"info-modal__title pl-6 pr-6 pb-4 pt-12",attrs:{"height":"24"}}):[_c('div',{staticClass:"info-modal__title pl-6 pr-6 pb-4 pt-12",attrs:{"data-test-id":"header"}},[_c('card-modal-toggle-table-items',{on:{"toggle":_vm.changeOpenedId}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.title))])],1)],_c('div',{staticClass:"info-modal__content-wrap"},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":"","value":_vm.openedPanels},on:{"update:value":function($event){_vm.openedPanels=$event}}},[_c('CardModalChapter',{attrs:{"title":"Общая информация","modalElements":_vm.baseModalElements,"isLoading":_vm.isLoading,"formValues":_vm.formValues,"modalType":_vm.type},scopedSlots:_vm._u([{key:"selectOik",fn:function(ref){
        var modalElement = ref.modalElement;
return [_c('SelectRolesCard',{attrs:{"isLoading":_vm.isLoading,"is-disabled":_vm.type === _vm.ModalType.READ,"title":modalElement.title,"tooltip":modalElement.tooltip,"id":_vm.fields.FUND_OIK,"items":_vm.oiks,"default-show-items":3,"is-multiple":false},model:{value:(_vm.formValues[_vm.fields.FUND_OIK]),callback:function ($$v) {_vm.$set(_vm.formValues, _vm.fields.FUND_OIK, $$v)},expression:"formValues[fields.FUND_OIK]"}})]}},{key:"selectArchive",fn:function(ref){
        var modalElement = ref.modalElement;
return [_c('SelectRolesCard',{attrs:{"isLoading":_vm.isLoading,"is-disabled":_vm.type === _vm.ModalType.READ,"title":modalElement.title,"tooltip":modalElement.tooltip,"id":_vm.fields.FUND_ARCHIVE,"items":_vm.archives,"default-show-items":3,"is-multiple":false},model:{value:(_vm.formValues[_vm.fields.FUND_ARCHIVE]),callback:function ($$v) {_vm.$set(_vm.formValues, _vm.fields.FUND_ARCHIVE, $$v)},expression:"formValues[fields.FUND_ARCHIVE]"}})]}}])}),(_vm.type !== _vm.ModalType.NEW && _vm.isShowTechData)?_c('CardModalChapter',{attrs:{"title":"Техническая информация","modalElements":_vm.techElements,"isLoading":_vm.isLoading,"formValues":_vm.formValues,"modalType":_vm.type}}):_vm._e()],1)],1),_c('div',{staticClass:"pa-6 info-modal__footer d-flex"},[(_vm.type === _vm.ModalType.NEW && _vm.isCanCreate)?[_c('v-btn',{staticClass:"info-modal__btn mr-2",attrs:{"loading":_vm.isSaveLoading,"type":"submit","width":"164","height":"40","color":"primary"}},[_c('span',[_vm._v("Сохранить")])]),_c('v-btn',{staticClass:"info-modal__btn",attrs:{"outlined":"","color":"primary","width":"148","height":"40"},on:{"click":_vm.closeModal}},[_c('span',[_vm._v("Отмена")])])]:_vm._e(),(_vm.type === _vm.ModalType.EDIT && _vm.isCanEdit)?[_c('v-btn',{staticClass:"info-modal__btn mr-2",attrs:{"loading":_vm.isSaveLoading,"type":"submit","color":"primary","width":"164","height":"40"}},[_c('span',[_vm._v("Сохранить")])]),_c('v-btn',{staticClass:"info-modal__btn",attrs:{"outlined":"","color":"primary","width":"148","height":"40"},on:{"click":_vm.closeModal}},[_c('span',[_vm._v("Отмена")])]),_c('v-spacer'),(_vm.isCanCreate)?_c('v-tooltip',{attrs:{"z-index":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"info-modal__download-btn mr-2",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.openNewModal}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#2462D1"}},[_vm._v("mdi-plus")])],1)]}}],null,false,2308316669)},[_c('div',[_vm._v("Создать новый фонд на основе данного")])]):_vm._e(),(_vm.isShowExport)?_c('ExportBtn',{attrs:{"mr":""},on:{"export":_vm.openExport}}):_vm._e(),(_vm.isShowDeleteBtn)?_c('DeleteBtn',{on:{"click":_vm.openDeleteModal}}):_vm._e()]:_vm._e(),(_vm.type === _vm.ModalType.READ)?[_c('v-btn',{staticClass:"info-modal__btn",attrs:{"width":"164","height":"40","color":"primary"},on:{"click":_vm.closeModal}},[_c('span',[_vm._v("Закрыть")])]),(_vm.formValues[_vm.fields.FUND_IS_DELETED] && _vm.isShowDeleteBtn)?_c('v-btn',{staticClass:"info-modal__btn ml-2",attrs:{"width":"164","height":"40","outlined":"","color":"primary"},on:{"click":_vm.openRestoreModal}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#2462D1"}},[_vm._v("mdi-delete-restore")]),_c('span',[_vm._v("Восстановить")])],1):_vm._e(),_c('v-spacer'),(_vm.isShowExport)?_c('ExportBtn',{attrs:{"mr":""},on:{"export":_vm.openExport}}):_vm._e()]:_vm._e()],2)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }