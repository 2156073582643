import axios from "axios";
import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { IFundCardElement } from "@monorepo/catalog/src/views/FundView/types/fundCardElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/fundView/elementStatuses";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { convertFiltersCustomToApi } from "@monorepo/catalog/src/views/FundView/utils/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { ISection } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { convertFiltersCustomToApi as convertFiltersArchiveToApi } from "@monorepo/catalog/src/views/ArchiveView/utils/convertFiltersToApi";
import { TreeItem } from "@monorepo/catalog/src/views/FundView/types/treeItem";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";

const archiveFilters = (): ITableFiltersObject => ({
  sort: {
    code: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 5000,
});

interface ITableState {
  section?: Sections;
  filters: ITableFiltersObject;
  totalLength: number;
  data: IFundElement[];
  treeData: TreeItem[];
  infiniteId: string;
  isOpenFilters: boolean;
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  libraries: {
    archives: IArchiveElement[];
    oik: IOikCardElement[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  viewMode: {
    id?: number;
    typeDisplay: ViewMode;
  };
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    [fields.FUND_CODE]: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_CATALOG_FUND_CSV;
    case "XLSX":
      return QUERY_PATH.GET_CATALOG_FUND_XLS;
    default:
      return QUERY_PATH.GET_CATALOG_FUND_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    section: Sections.FUND,
    filters: defaultFilters(),
    treeData: [],
    infiniteId: new Date().toString(),
    sectionCells: {} as ISection,
    isOpenFilters: false,
    totalLength: 0,
    searchTemplates: [],
    cells: [],
    data: [],
    libraries: {
      archives: [],
      oik: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    viewMode: {
      typeDisplay: ViewMode.TABLE,
    },
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    setTreeData(
      state: ITableState,
      payload: {
        data: TreeItem[];
        totalLength: number;
      }
    ) {
      state.treeData = payload.data;
    },
    addArchivesLib(state: ITableState, payload: IArchiveElement[]) {
      state.libraries.archives =
        payload.map((archive) => {
          return { ...archive, label: archive.name };
        }) || [];
    },
    addOikLib(state: ITableState, payload: IOikCardElement[]) {
      state.libraries.oik =
        payload.map((oik) => {
          return { ...oik, label: oik.shortName };
        }) || [];
    },
    addData(
      state: ITableState,
      payload: {
        data: IFundElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getTreeData(info) {
      const params = convertFiltersToApi({ ...info.state.filters, initMessagesLength: 50 }, 0, convertFiltersCustomToApi, false, true);
      const { data, total: totalLength } = await getQuery<TreeItem[]>(QUERY_PATH.GET_FUND_TREE_LIST, params).finally(() => {
        info.commit("setTableLoading", false);
      });
      if (data !== null) {
        info.commit("setTreeData", { data: data || [], totalLength: totalLength || 0 });
        return { data: info.state.treeData || [], totalLength: totalLength || 0 };
      }
      return { data: null, error: "error" };
    },
    async getInventoryTreeData(info, { length, fundCode }) {
      const params = {
        offset: length,
        limit: 50,
      };
      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_INV_TREE_LIST}/${fundCode}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getEadTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_EAD_BY_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getCaseTreeData(info, { length, id }) {
      const params = {
        offset: length,
        limit: 50,
      };

      const { data, total: totalLength } = await getQuery<TreeItem[]>(`${QUERY_PATH.GET_CASE_TREE_LIST}/${id}`, params).finally(() => {
        info.commit("setTableLoading", false);
      });

      if (data !== null) {
        return { data: data || [], totalLength: totalLength || 0 };
      }

      return { data: [], totalLength: 0 };
    },
    async getFundIdAutocompleteItems(info, value: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(`${QUERY_PATH.GET_FUND_AUTOCOMPLETE_ITEMS_BY_ID}/like`, { keyword: value }, false);
      if (data !== null) {
        return data.map((item: { id: number }) => ({
          ...item,
          titleValue: item.id,
        }));
      }
      return [];
    },
    async getAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_FUND_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getAutocompleteByName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_FUND_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode && !!params ? item.code : item.name,
        }));
      }
      return [];
    },
    async getAutocompleteByNumber(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST + `/by-number/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { number: string }) => ({
          ...item,
          titleValue: item.number,
        }));
      }
      return [];
    },
    async getAutocompleteArchiveByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST + `/by-short-name/like`, { keyword: params }, false);
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async deleteElement({ state }, params: Record<string, unknown>) {
      try {
        const { filters } = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
        await axios.delete(getFullPath(params?.deleteAll ? `${QUERY_PATH.GET_CATALOG_FUND_LIST}/by-filter` : QUERY_PATH.GET_CATALOG_FUND_LIST), {
          params: params?.deleteAll ? { filters } : params,
          paramsSerializer,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async restoreEntity(info, id: string | number) {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_FUND_LIST), { id, isDeleted: false });
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      try {
        await axios[!data.id ? "post" : "put"](getFullPath(QUERY_PATH.GET_CATALOG_FUND_LIST), data).catch(function (error) {
          const messagesArr = Object.values(error?.response?.data?.data || {});
          showNotification(messagesArr.length ? messagesArr.join(". ") : error?.response?.data?.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async getCardElement(info, id: string) {
      const { data } = await getQuery<IFundCardElement>(`${QUERY_PATH.GET_CATALOG_FUND_LIST}/${id}`);
      return data;
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IFundElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async concatFunds(info, params: { masterFundId: string; mergedFundId: string[] }) {
      try {
        await axios.post(getFullPath(QUERY_PATH.CONCATED_FUND), params);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
        root: true,
      });
    },
    getArchives({ commit, state }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      checkExistLibrary(state.libraries.archives, async () => {
        const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST, resultParams);
        commit("addArchivesLib", data || []);
      });
    },
    getOiks({ commit, state }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      checkExistLibrary(state.libraries.archives, async () => {
        const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, resultParams);
        commit("addOikLib", data || []);
      });
    },
    refreshScroll({ commit }) {
      commit("refreshData");
      setTimeout(() => {
        // TODO rewrite, error with infinite scroll this.$refs.infiniteLoader.getCurrentDistance() not refreshed to [] data
        commit("refreshInfiniteId");
      }, 100);
    },
  },
  getters: {
    ...baseGetters,
    archives(state: ITableState) {
      return state.libraries.archives || [];
    },
    oiks(state: ITableState) {
      return state.libraries.oik || [];
    },
    treeData(state: ITableState) {
      return state.treeData || [];
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
