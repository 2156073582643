








































































































































import { defineComponent, ref } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { mapActions, mapGetters } from "vuex";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import DeleteBtn from "@monorepo/uikit/src/components/tableViews/DeleteBtn.vue";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { IFundCardElement } from "@monorepo/catalog/src/views/FundView/types/fundCardElement";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundView/viewTitle";
import {
  baseModalElements,
  fullModalElements as modalElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/fundView/modalElements";
import { convertStatus } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import moment from "moment";
import { convertSaveCardObject } from "@monorepo/catalog/src/views/FundView/utils/convertSaveCardObject";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { fields, IFundFields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { Route } from "vue-router";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";

export default defineComponent({
  name: "FundInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    CardModalStatusChip,
    SelectRolesCard,
    DeleteBtn,
    CardModalToggleViewSize,
    ExportBtn,
    CardModalToggleTableItems,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data(): {
    ModalType: typeof ModalType;
    key: string;
    viewUniqKey: string;
    defaultFilterPlaceholder: string;
    modalElements: IModalElementType[];
    isOpenByTemplate: boolean;
    baseModalElements: IModalElementType[];
    techElements: IModalElementType[];
    isSaveLoading: boolean;
    openedPanels: number[];
    fields: IFundFields;
    cardMode: typeof CardMode;
    section: Sections;
    isLoading: boolean;
  } {
    return {
      ModalType,
      isSaveLoading: false,
      isOpenByTemplate: false,
      key: uuid(),
      viewUniqKey,
      defaultFilterPlaceholder,
      modalElements,
      baseModalElements,
      techElements,
      cardMode: CardMode,
      section: Sections.FUND,
      openedPanels: [0, 1],
      fields,
      isLoading: false,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getCardElement: (val: string) => Promise<IFundCardElement>;
          formValues: Partial<IFundCardElement>;
          isOpenByTemplate: boolean;
          changedFormValues: Partial<IFundCardElement>;
          getResultSaveData: (data: Partial<IFundCardElement>) => Partial<IFundCardElement>;
          key: string;
          $route: Route;
          $emit: (event: string, ...args: unknown[]) => void;
          user: { authorities: { authority: string }[] };
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value: string
      ) {
        if (this.isOpenByTemplate) {
          // Открытие по шаблону
          this.isOpenByTemplate = false;
          return;
        }

        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getCardElement(value)
            .then((data: IFundCardElement) => {
              const archive = data[fields.FUND_ARCHIVE as keyof IFundCardElement] as IArchiveElement;
              const archiveResult = {
                ...(archive || {}),
                label: archive?.shortName,
              };

              this.$emit(
                "changeType",
                !data.isEditable
                  ? ModalType.READ
                  : this.user.authorities?.find((element: { authority: string }) => {
                      return element.authority === authorities.FUND_MODIFY;
                    }) && !data.isDeleted
                  ? ModalType.EDIT
                  : ModalType.READ
              );

              const oik = data[fields.FUND_OIK as keyof IFundCardElement] as IArchiveElement;
              const oikResult = oik
                ? {
                    ...(oik || {}),
                    label: oik?.shortName,
                  }
                : null;

              this.formValues = {
                //todo: need refactoring
                ...data,
                [fields.FUND_ARCHIVE]: archiveResult,
                [fields.FUND_OIK]: oikResult,
                [fields.FUND_STATUS]: convertStatus(!!data[fields.FUND_IS_DELETED as keyof IFundCardElement]),
                [fields.FUND_CREATION_DATE]: data[fields.FUND_CREATION_DATE as keyof IFundCardElement]
                  ? moment((data[fields.FUND_CREATION_DATE as keyof IFundCardElement] as string).toString()).format("YYYY-MM-DD HH:mm")
                  : "",
                [fields.FUND_UPDATE_DATE]: data[fields.FUND_UPDATE_DATE as keyof IFundCardElement]
                  ? moment((data[fields.FUND_UPDATE_DATE as keyof IFundCardElement] as string).toString()).format("YYYY-MM-DD HH:mm")
                  : "",
                [fields.FUND_DATE_FIRST_ARRIVAL]: data[fields.FUND_DATE_FIRST_ARRIVAL as keyof IFundCardElement]
                  ? moment((data[fields.FUND_DATE_FIRST_ARRIVAL as keyof IFundCardElement] as string).toString()).format("YYYY-MM-DD")
                  : "",
                [fields.FUND_DATE_LAST_ARRIVAL]: data[fields.FUND_DATE_LAST_ARRIVAL as keyof IFundCardElement]
                  ? moment((data[fields.FUND_DATE_LAST_ARRIVAL as keyof IFundCardElement] as string).toString()).format("YYYY-MM-DD")
                  : "",
              };
              this.key = uuid();
              this.changedFormValues = this.getResultSaveData(this.formValues);
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.changedFormValues = this.getResultSaveData({});
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("fundCatalogView", ["data", "openedId", "archives", "oiks"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_MODIFY);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_EXPORT);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.FUND_DELETE);
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    statusColor(): string {
      const status: string = (this.formValues as IFundCardElement)[fields.FUND_STATUS as keyof IFundCardElement] as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Удален":
          return "#D34039";
        default:
          return "";
      }
    },
    title(): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return (this.formValues as IFundCardElement).name as string;
        case ModalType.NEW:
        default:
          return "Новый фонд";
      }
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("fundCatalogView", ["changeOpenedId"]),
    ...mapActions("fundCatalogView", ["getCardElement", "saveCard", "refreshScroll", "deleteElement", "restoreEntity"]),
    openExport() {
      this.openNewWindow(getFullPath(QUERY_PATH.GET_CATALOG_FUND_PDF, "/", (this.formValues as IFundCardElement).id as string));
    },
    openNewModal() {
      this.$emit("changeType", ModalType.NEW);
      this.$router.replace({ query: {} });
      const resultData = this.getResultSaveData(this.formValues as IFundCardElement);
      delete resultData.id;
      delete resultData.code;
      delete resultData.archive;
      this.formValues = {
        ...resultData,
        oik: (this.formValues as IFundCardElement).oik,
        archive: (this.formValues as IFundCardElement).archive,
      } as IFundCardElement;
      this.key = uuid();
      this.isOpenByTemplate = true;
    },
    openDeleteModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.deleteElementCb,
          title: `Удалить фонд «${(this.formValues as IFundCardElement).name}»?`,
        })
      );
    },
    openRestoreModal() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.restoreOikCb,
          yesBtnTitle: "Да, восстановить",
          icon: "mdi-delete-restore",
          title: `Восстановить фонд «${(this.formValues as IFundCardElement).name}»?`,
        })
      );
    },
    moveToArchive(id: string) {
      if (!id) {
        return;
      }
      this.$router.push({
        path: "/dictionaries/archive",
        query: { id, isOpenModal: "1" },
      });
    },
    async deleteElementCb() {
      const isDeleted = await this.deleteElement({ ids: [(this.formValues as IFundCardElement).id] });
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка удаления фонда");
      }
    },
    async restoreOikCb() {
      const isDeleted = await this.restoreEntity((this.formValues as IFundCardElement).id);
      if (isDeleted) {
        this.refreshScroll();
        this.closeModal();
      } else {
        showNotification("Ошибка восстановления фонда");
      }
    },
    getResultSaveData(data: Partial<IFundCardElement>) {
      const resultData: Partial<IFundCardElement> = {
        id: data.id || (this.formValues as IFundCardElement).id,
        ...data,
        archive: (this.formValues as IFundCardElement).archive || {},
      };
      return convertSaveCardObject(resultData);
    },
    async onSave(data: Record<string, string | boolean>) {
      const isNeedSave = (this as unknown as { checkChangesBeforeSave: () => boolean }).checkChangesBeforeSave();
      if (isNeedSave) {
        const resultData = this.getResultSaveData(data);
        if (!Object.keys(resultData?.[this.fields.FUND_ARCHIVE as keyof IFundCardElement] || {}).length) {
          showNotification("Поле архив обязательно для заполнения");
          return;
        }

        this.isSaveLoading = true;
        const isSaved = await this.saveCard(resultData).finally(() => {
          this.isSaveLoading = false;
        });
        if (isSaved) {
          this.changedFormValues = resultData;
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.refreshScroll();
          this.closeModal();
        }
      } else {
        return;
      }
    },
    changeContracts(contracts: Record<string, string>[], values: Record<string, string>) {
      (
        this as unknown as {
          $formulate: {
            setValues: (key: string, object: Record<string, string>) => void;
          };
        }
      ).$formulate.setValues("oik-info-modal", Object.assign(values, { contracts }));
    },
    closeModal(): void {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({ archive: null, oik: null } as IFundCardElement);
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "fundCatalogView", Sections.FUND, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "fundCatalogView");

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
