import { convertStatus } from "@monorepo/utils/src/api/convertDeleteStatus";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { fields } from "@monorepo/utils/src/variables/projectsData/fundView/fields";

const convertSortKey = (key: string): string => {
  switch (key) {
    case "status":
      return "isDeleted";
    case "fundParentId":
      return "archive.shortName";
    default:
      return key;
  }
};

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & {
    fieldFilters: {
      dateFirstArrivalTo?: string;
      dateFirstArrivalFrom?: string;
      dateLastArrivalTo?: string;
      dateLastArrivalFrom?: string;
      status?: string;
    };
  }
): void => {
  formDateFilter(resultFilters, "dateFirstArrival");
  formDateFilter(resultFilters, "dateLastArrival");
  convertStatus(resultFilters, "isDeleted");
  convertAutocompleteItemToApi(resultFilters, fields.FUND_CODE, `fieldFilters.${fields.FUND_CODE}.code`);
  convertAutocompleteItemToApi(resultFilters, fields.FUND_NUMBER, `fieldFilters.${fields.FUND_NUMBER}.number`);
  convertAutocompleteItemToApi(resultFilters, fields.FUND_NAME, `fieldFilters.${fields.FUND_NAME}.name`);
  convertAutocompleteItemToApi(resultFilters, fields.FUND_SHORT_NAME, `fieldFilters.${fields.FUND_SHORT_NAME}.shortName`);
  convertAutocompleteItemToApi(resultFilters, fields.FUND_ID, `fieldFilters.${fields.FUND_ID}.id`);
  resultFilters.sort = Object.fromEntries(Object.entries(resultFilters.sort || {}).map(([key, value]) => [convertSortKey(key), value]));
};
